'use client'

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { usePathname } from 'next/navigation';

export const FaviconRandomizer: React.FC = () => {
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const faviconRef = useRef<HTMLLinkElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  
    const getRandomColor = useCallback(() => {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return `rgb(${r},${g},${b})`;
    }, []);
  
    const updateFavicon = useCallback(() => {
      if (!ctxRef.current || !faviconRef.current || !canvasRef.current) return;
  
      ctxRef.current.clearRect(0, 0, 16, 16);
      ctxRef.current.beginPath();
      for (let x = 0; x < 16; x++) {
        for (let y = 0; y < 16; y++) {
          if ((x - 8) ** 2 + (y - 8) ** 2 <= 8 ** 2) {
            ctxRef.current.fillStyle = getRandomColor();
            ctxRef.current.fillRect(x, y, 1, 1);
          }
        }
      }
      ctxRef.current.closePath();
      faviconRef.current.href = canvasRef.current.toDataURL();
    }, [getRandomColor]);
  
    useEffect(() => {
      faviconRef.current = document.querySelector('link[rel="icon"]');
      if (!faviconRef.current) {
        faviconRef.current = document.createElement('link');
        faviconRef.current.rel = 'icon';
        document.head.appendChild(faviconRef.current);
      }
  
      canvasRef.current = document.createElement('canvas');
      canvasRef.current.width = 16;
      canvasRef.current.height = 16;
      ctxRef.current = canvasRef.current.getContext('2d');
  
      intervalRef.current = setInterval(updateFavicon, 100);
  
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }, [updateFavicon]);
  
    return null;
  };

export const FaviconRandomizerWrapper: React.FC = () => {
    const pathname = usePathname();
    const [key, setKey] = useState(0);
  
    useEffect(() => {
      setKey(prev => prev + 1);
    }, [pathname]);
  
    return <FaviconRandomizer key={key} />;
  };