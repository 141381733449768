'use client'

import React, { Suspense, useEffect } from "react";
import { Inter } from "next/font/google";
import "../styles/globals.css";
import { themeChange } from 'theme-change';
import RandomsMenu from "@/components/ui/RandomsMenu";
import { FaviconRandomizerWrapper } from "@/context/Favicon";
import NavBar from "@/components/ui/NavBar";
import { usePathname, useSearchParams } from "next/navigation";
import { databases, ID } from "@/lib/appwrite";
import Logger from "@/components/Logger";

const inter = Inter({ subsets: ["latin"] });

export default function RootLayout({ children }: { children: React.ReactNode }) {

    return (
        <html lang="en" suppressHydrationWarning>
            <head>
                <title>The Randoms Generator</title>
                <meta name="description" content="The Randoms Generator" />
            </head>
            <body className={`${inter.className}`}>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              (function() {
                const localStorageTheme = localStorage.getItem("theme");
                document.documentElement.setAttribute("data-theme", localStorageTheme || "mytheme");
              })();
            `,
                    }}
                />
                <FaviconRandomizerWrapper />
                <div className="drawer lg:drawer-open drawer-auto-gutter">
                    <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-content flex flex-col items-center justify-center">
                        <NavBar />
                        <div className="flex-grow overflow-auto w-full">
                            <div className="container mx-auto px-4">
                                <Suspense fallback={<div>Loading...</div>}>
                                    {children}
                                    <Logger />
                                </Suspense>
                            </div>
                        </div>
                    </div>
                    <div className="drawer-side">
                        <RandomsMenu />
                    </div>
                </div>
            </body>
        </html>
    )
}