import ThemeToggle from './ThemeToggle';

function NavBar() {
    return (
        <div className="navbar bg-base-200">
            <div className="flex-none">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="inline-block h-5 w-5 stroke-current">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </label>
            </div>
            <div className="flex-1">
                <a className="text-2xl max-w-fit text-center text-primary">The Randoms Generator</a>
            </div>
            <div className="flex-none">
                <ThemeToggle />
            </div>
        </div>
    )
}

export default NavBar;