import Link from "next/link";

function MenuTitle({ title }: { title: string }): JSX.Element {
    return (
        <li className="menu-title text-lg text-primary">{title}</li>
    );
}

function MenuItems({ title, href }: { title: string, href: string }): JSX.Element {
    return (
        <li className="menu-item hover:bg-primary hover:text-primary-content rounded-none text-primary">
            <Link href={href || "/"} style={{ padding: "0", borderRadius: "0" }}>
                <div className="text-lg ml-8">{title}</div>
            </Link>
        </li>
    );
}

function RandomsMenu(): JSX.Element {
    return (
        <>
            <label htmlFor="my-drawer-2" aria-label="close sidebar" className="drawer-overlay"></label>
            <ul className="menu bg-base-200 text-base-content min-h-full w-56 p-0">
                {/* <h2 className="menu-title text-lg">Randoms</h2> */}
                <MenuTitle title="Randoms" />

                <MenuItems title="Passwords" href="/randoms/passwords" />
                <MenuItems title="Lorem Ipsum" href="/randoms/lorem-ipsum" />
                <MenuItems title="UUID" href="/randoms/uuid" />
                <MenuItems title="Files" href="/randoms/files" />
                <MenuItems title="Colors" href="/randoms/colors" />
                <MenuItems title="Numbers" href="/randoms/numbers" />
                <MenuItems title="Dates" href="/randoms/dates" />
                <MenuItems title="Choices" href="/randoms/choices" />
                <MenuItems title="Countries" href="/randoms/countries" />


                <MenuTitle title="Tools" />

                <MenuItems title="String Length" href="/tools/string-length" />
                <MenuItems title="String Reverse" href="/tools/string-reverse" />
                <MenuItems title="String Shuffle" href="/tools/string-shuffle" />
                <MenuItems title="String Compare" href="/tools/string-compare" />
                <MenuItems title="Hexdump" href="/tools/hexdump" />
                <MenuItems title="Base64 Enc/Dec" href="/tools/base64-enc-dec" />
                <MenuItems title="URL Enc/Dec" href="/tools/url-enc-dec" />
                <MenuItems title="Hash" href="/tools/hash" />

            </ul>
        </>
    );
}

export default RandomsMenu;