'use client'

import React, { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

function Logger() {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        console.log("Pathname changed to", pathname);
        const callLogger = async () => {
            const result = await fetch("/api",
                {
                    method: "POST",
                    body: JSON.stringify({
                        method: "POST",
                        url: pathname,
                        body: "",
                        query: searchParams.toString(),
                        ip: ""
                    })
                }
            );
        }
        callLogger();
    }, [pathname, searchParams]);

    return null; // This component doesn't render anything
}

export default Logger;